import React, { useEffect, useState } from 'react';
import Step from '../Step';
import Form from '../../UI/Form/Form';
import { useContactFormData } from '../useContactFormData';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button from '../../UI/Button/Button';

const BookStep1 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });

  const { 
    allLocations: {
      nodes
    },
  } = useContactFormData();

  function getFormData(form) {
    const obj = getFormDataAsObject(form);
    delete obj.when;
    return obj;
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  function handleFormChange(e) {
    setFormData(getFormData(e.target.form));
  }

  useEffect(() => {
    setFormData({ ...data });
  }, [ data ]);

  return (
    <Step title="At which Prime Health centre would you like your appointment?" selectedValue={data?.centre} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        <Form.Row>
          <Form.Fieldset>
            {nodes.map((location, index) => {
              return (
                location.value === 'Brighton' ?
                  <Form.Radio 
                    label={location.label} 
                    name="centre" 
                    id={`centre${index}`} 
                    value={location.value} 
                    required 
                    defaultChecked={data?.centre === location.value}
                    data-form-id={location.locationDetails.formId}
                    key={index}
                  />
                  : null               
              )
            })}
          </Form.Fieldset>
        </Form.Row>

        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};


export default BookStep1;